import * as React from 'react'

import { Helmet } from 'react-helmet'

import { AppContext } from '../contexts'

type BlogPostSchema = {
  '@context': 'https://schema.org'
  '@type': 'BlogPosting'
  'mainEntityOfPage': {
    '@type': 'WebPage'
    '@id': string
  }
  'headline': string
  'description': string
  'image': string
  'author': {
    '@type': 'Person'
    'name': string
  }
  'datePublished': string
}

type FaqSchema = {
  '@context': 'https://schema.org'
  '@type': 'FAQPage'
  'mainEntity': {
    '@type': 'Question'
    'name': string
    'acceptedAnswer': {
      '@type': string
      'text': string
    }
  }[]
}

type MetaProps = JSX.IntrinsicElements['meta']
type LinkProps = JSX.IntrinsicElements['link']

export type SeoHeadersProps = {
  title?: string
  description?: string
  ogType?: string
  ogImage?: string
  meta?: MetaProps[]
  link?: LinkProps[]
  schemaMarkup?: BlogPostSchema | FaqSchema
}

export const SeoHeaders = ({
  title,
  description,
  ogType,
  ogImage,
  meta = [],
  link = [],
  schemaMarkup,
}: SeoHeadersProps) => {
  const { appName, appTitle, defaultOgImage } = React.useContext(AppContext)

  return (
    <Helmet
      htmlAttributes={{
        lang: 'es',
      }}
      titleTemplate={`${appName} - %s`}
      defaultTitle={`${appName} - ${appTitle}`}
      title={title}
      meta={[
        ...(description ? [{ name: 'description', content: description }] : []),
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:type',
          content: ogType || 'website',
        },
        {
          property: 'og:image',
          content: ogImage || defaultOgImage,
        },
        ...meta,
      ]}
      link={[
        ...link,
      ]}
    >
      {schemaMarkup &&
      <script type='application/ld+json'>{JSON.stringify(schemaMarkup)}</script>
      }
    </Helmet>
  )
}
