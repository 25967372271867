import * as React from 'react'

import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import * as Yup from 'yup'

import { DomainContext } from '../contexts'
import { FUNDS_MANAGER_RELATIONSHIP_LABELS } from '../queries'

import type { FundsManagerRelationship } from '../queries'

export type FundsManagerRelationshipFormValues = {
  fundsManagerRelationship: string
}

export const getFundsManagerRelationshipInitialValues =
  (data?: FundsManagerRelationship): FundsManagerRelationshipFormValues => ({
    fundsManagerRelationship: data?.kind || '',
  })

export const fundsManagerRelationshipValidationSchema: Yup.SchemaOf<FundsManagerRelationshipFormValues> =
  Yup.object({
    fundsManagerRelationship: Yup.string()
      .oneOf(Object.keys(FUNDS_MANAGER_RELATIONSHIP_LABELS))
      .required('Este campo es obligatorio'),
  })

export const FundsManagerRelationshipFields = () => {
  const { fundsManagerName } = React.useContext(DomainContext)

  return (
    <Field
      required
      name='fundsManagerRelationship'
      type='text'
      label={`Relación con ${fundsManagerName}`}
      component={Select}
      formControl={{ sx: { width: '100%', my: 3 } }}
    >
      {Object.entries(FUNDS_MANAGER_RELATIONSHIP_LABELS).map(
        ([relationType, relationLabel]) => (
          <MenuItem
            key={relationType}
            value={relationType}
          >
            {relationLabel}
          </MenuItem>
        ))}
    </Field>
  )
}
