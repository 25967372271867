import * as React from 'react'

import { numberFormatter } from '../services'

const fiatDisplayNames: { [key: string]: string } = {
  USD: 'US$',
  CLP: 'CLP$',
}

const currencyDisplayNameStyle = {
  fontSize: '.8em',
  fontWeight: 'bold',
}

const digitsToDisplay = (number: number, digits?: number, fiatDisplayName?: string) => {
  if (typeof digits !== 'undefined') {
    return digits
  } else if (Math.abs(number % 1) < 0.000001) {
    return 2
  } else if (number === 0) {
    return 2
  } else if (typeof fiatDisplayName !== 'undefined') {
    const absValue = Math.abs(number)

    if (absValue < 100) {
      return 6
    } else if (absValue < 1_000) {
      return 4
    } else {
      return 2
    }
  } else if (number >= 1_000) {
    return 2
  } else if (number >= 100) {
    return 4
  } else if (number >= 10) {
    return 6
  } else {
    return 8
  }
}

export type CurrencyProps = {
  currency: string
  value: number | undefined
  digits?: number
  signed?: boolean
  svg?: boolean
}

export const Currency = ({
  currency,
  value,
  digits,
  signed,
  svg,
}: CurrencyProps) => {
  const hasValue = typeof value !== 'undefined'

  const fiatDisplayName = fiatDisplayNames[currency]
  const currencyDisplayName = hasValue ? (fiatDisplayName || currency) : ''

  const displayDigits = digitsToDisplay(value || 0, digits, fiatDisplayName)
  const formattedValue = hasValue ? numberFormatter(displayDigits).format(value) : '--'

  return (
    <React.Fragment>
      {svg
        ? <tspan style={currencyDisplayNameStyle}>{currencyDisplayName}</tspan>
        : <span style={currencyDisplayNameStyle}>{currencyDisplayName}</span>
      }
      &nbsp;{signed && hasValue && value > 0 && '+'}
      {formattedValue}
    </React.Fragment>
  )
}
