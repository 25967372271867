import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Divider,
  Stack,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Dialog, ErrorDisplay, Loading } from '../../../components'
import {
  BaseIdentityFields,
  CivilStateFields,
  FundsManagerRelationshipFields,
  baseIdentityValidationSchema,
  civilStateValidationSchema,
  fundsManagerRelationshipValidationSchema,
  getBaseIdentityInitialValues,
  getCivilStateInitialValues,
  getFundsManagerRelationshipInitialValues,
} from '../../../forms'
import {
  REPRESENTATIVE_IDENTITY_QUERY,
  UPDATE_REPRESENTATIVE_IDENTITY_MUTATION,
} from '../../../queries'
import { setFormError, toISO8601Date, translateError } from '../../../services'
import { PersonIdentityDisplay } from '../person_identity_display'

import type {
  BaseIdentityFormValues,
  CivilStateFormValues,
  FundsManagerRelationshipFormValues,
} from '../../../forms'
import type {
  RepresentativeIdentity,
  RepresentativeIdentityData,
  RepresentativeIdentityVars,
  UpdateRepresentativeIdentityData,
  UpdateRepresentativeIdentityVars,
} from '../../../queries'
import type { FormikProps } from 'formik'

type FormValues =
  BaseIdentityFormValues
  & CivilStateFormValues
  & FundsManagerRelationshipFormValues

const getInitialValues = (data?: RepresentativeIdentity): FormValues => ({
  ...getBaseIdentityInitialValues(data),
  ...getCivilStateInitialValues(data),
  ...getFundsManagerRelationshipInitialValues(data?.fundsManagerRelationship),
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    ...baseIdentityValidationSchema.fields,
    ...civilStateValidationSchema.fields,
    ...fundsManagerRelationshipValidationSchema.fields,
  })

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form style={{ width: '100%' }}>
    <Stack spacing={3}>
      <BaseIdentityFields />
      <CivilStateFields />
      <FundsManagerRelationshipFields />
      <ErrorDisplay errorMsg={status?.errorMsg} />
      <Button
        fullWidth
        disabled={isSubmitting || !isValid}
        onClick={submitForm}
        variant='contained'
      >
        Confirmar
      </Button>
    </Stack>
  </Form>
)

export const RepresentativeIdentityForm = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [dialogOpen, setDialogOpen] = React.useState(false)

  const { loading, data } =
    useQuery<RepresentativeIdentityData, RepresentativeIdentityVars>(REPRESENTATIVE_IDENTITY_QUERY)

  const [updateRepresentativeIdentity] =
    useMutation<UpdateRepresentativeIdentityData, UpdateRepresentativeIdentityVars>(
      UPDATE_REPRESENTATIVE_IDENTITY_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [ REPRESENTATIVE_IDENTITY_QUERY ],
      })

  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  const handleSubmit = async (values: FormValues) => {
    const response = await updateRepresentativeIdentity({
      variables: {
        ...values,
        birthdate: toISO8601Date(values.birthdate),
      },
    })

    if (response.data?.updateRepresentativeIdentity === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateError(response))
  }

  return loading ? (<Loading />) : (
    <React.Fragment>
      {(data?.representativeIdentity) ? (
        <React.Fragment>
          <Divider sx={{ marginBottom: 1, marginTop: 2 }}>
            <Stack
              direction='row'
              alignItems='center'
            >
              Mi apoderado
            </Stack>
          </Divider>
          <PersonIdentityDisplay
            personIdentity={data.representativeIdentity}
            identityType='REPRESENTATIVE_IDENTITY'
          />
        </React.Fragment>
      ) : (
        <Button
          fullWidth
          onClick={openDialog}
          variant='outlined'
          size='large'
          sx={{ marginBottom: 1, marginTop: 2 }}
        >
          Agregar apoderado (opcional)
        </Button>
      )}
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title='Datos del apoderado'
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <Formik
            innerRef={formRef}
            initialValues={getInitialValues(data?.representativeIdentity)}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => <InnerForm {...props} />}
          </Formik>
        </LocalizationProvider>
      </Dialog>
    </React.Fragment>
  )
}
