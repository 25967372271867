import * as React from 'react'

import { ErrorMessage, useFormikContext } from 'formik'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import * as Yup from 'yup'

import type { PhoneNumber } from '../queries'

export type PhoneNumberFormValues = {
  primaryPhoneNumber: string
  secondaryPhoneNumber?: string
}

export const getPhoneNumberInitialValues =
  (data?: PhoneNumber): PhoneNumberFormValues => ({
    primaryPhoneNumber: data?.primary || '',
    secondaryPhoneNumber: data?.secondary || '',
  })

export const phoneNumberValidationSchema: Yup.SchemaOf<PhoneNumberFormValues> =
  Yup.object({
    primaryPhoneNumber: Yup.string()
      .required('Este campo es obligatorio')
      .test({
        message: 'El número de teléfono es inválido',
        test: (value) => matchIsValidTel(value || ''),
      }),
    secondaryPhoneNumber: Yup.string()
      .when({
        is: (value: string) => value && value.length > 0,
        then: Yup.string()
          .test({
            message: 'El número de teléfono es inválido',
            test: (value) => matchIsValidTel(value || ''),
          }),
      }),
  })

export const PhoneNumberFields = <FormValues extends PhoneNumberFormValues>() => {
  const { errors, touched, values, setFieldValue, setFieldTouched } = useFormikContext<FormValues>()

  return (
    <React.Fragment>
      <MuiTelInput
        name='primaryPhoneNumber'
        fullWidth
        required
        defaultCountry='CL'
        langOfCountryName='es'
        label='Teléfono principal'
        helperText={touched.primaryPhoneNumber && <ErrorMessage name='primaryPhoneNumber' />}
        error={touched.primaryPhoneNumber && !!errors.primaryPhoneNumber}
        value={values.primaryPhoneNumber}
        onChange={(newValue) => setFieldValue('primaryPhoneNumber', newValue)}
        onBlur={() => setFieldTouched('primaryPhoneNumber')}
        sx={{ mt: 2, mb: 1 }}
      />
      <MuiTelInput
        name='secondaryPhoneNumber'
        fullWidth
        defaultCountry='CL'
        langOfCountryName='es'
        label='Teléfono secundario (opcional)'
        helperText={touched.secondaryPhoneNumber && <ErrorMessage name='secondaryPhoneNumber' />}
        error={touched.secondaryPhoneNumber && !!errors.secondaryPhoneNumber}
        value={values.secondaryPhoneNumber}
        onChange={(newValue) => setFieldValue('secondaryPhoneNumber', newValue)}
        onBlur={() => setFieldTouched('secondaryPhoneNumber')}
        sx={{ mt: 2, mb: 1 }}
      />
    </React.Fragment>
  )
}
