import * as React from 'react'

import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'

import { booleanToString, stringToBoolean } from '../services'

import type {
  InvestorProfile,
  UpdateInvestorProfileVars,
} from '../queries'

export type InvestorProfileFormValues = {
  declaresLargeInvestments: string
  isBrokerageEmployee: string
  isInstitutionalInvestor: string
  isPoliticallyExposed: string
  isUsCitizen: string
}

export const getInvestorProfileInitialValues = (
  data:  InvestorProfile | undefined,
): InvestorProfileFormValues => ({
  declaresLargeInvestments: booleanToString(data?.declaresLargeInvestments),
  isBrokerageEmployee: booleanToString(data?.isBrokerageEmployee),
  isInstitutionalInvestor: booleanToString(data?.isInstitutionalInvestor),
  isPoliticallyExposed: booleanToString(data?.isPoliticallyExposed),
  isUsCitizen: booleanToString(data?.isUsCitizen),
})

export const getInvestorProfileMutationVars = (
  values: InvestorProfileFormValues,
): UpdateInvestorProfileVars => ({
  declaresLargeInvestments: stringToBoolean(values.declaresLargeInvestments),
  isBrokerageEmployee: stringToBoolean(values.isBrokerageEmployee),
  isInstitutionalInvestor: stringToBoolean(values.isInstitutionalInvestor),
  isPoliticallyExposed: stringToBoolean(values.isPoliticallyExposed),
  isUsCitizen: stringToBoolean(values.isUsCitizen),
})

export const investorProfileValidationSchema: Yup.SchemaOf<InvestorProfileFormValues> =
  Yup.object().shape({
    isBrokerageEmployee: Yup.string()
      .oneOf(['true', 'false'], 'Debe seleccionar una opción')
      .required('Debe seleccionar una respuesta'),
    isPoliticallyExposed: Yup.string()
      .oneOf(['true', 'false'], 'Debe seleccionar una opción')
      .required('Debe seleccionar una respuesta'),
    isInstitutionalInvestor: Yup.string()
      .oneOf(['true', 'false'], 'Debe seleccionar una opción')
      .required('Debe seleccionar una respuesta'),
    declaresLargeInvestments: Yup.string()
      .oneOf(['true', 'false'], 'Debe seleccionar una opción')
      .required('Debe seleccionar una respuesta'),
    isUsCitizen: Yup.string()
      .oneOf(['true', 'false'], 'Debe seleccionar una opción')
      .required('Debe seleccionar una respuesta'),
  })

type BooleanOptionProps = {
  label: string
  name: keyof InvestorProfileFormValues
}

const BooleanOption = ({
  label,
  name,
}: BooleanOptionProps) => {
  const { errors, isSubmitting, setFieldValue, touched, values } = useFormikContext<InvestorProfileFormValues>()

  return (
    <Stack alignItems='center'>
      <Typography textAlign='center'>
        {label}
      </Typography>
      <FormControl>
        <RadioGroup
          row
          name={name}
          value={values[name]}
          onChange={(event) => setFieldValue(name, event.target.value)}
          sx={{ justifyContent: 'space-evenly' }}
        >
          <FormControlLabel
            value='true'
            label='Sí'
            control={<Radio />}
            disabled={isSubmitting}
          />
          <FormControlLabel
            value='false'
            label='No'
            control={<Radio />}
            disabled={isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <FormHelperText error>
        {touched[name] && errors[name]}
      </FormHelperText>
    </Stack>
  )
}

export const InvestorProfileFields = () => (
  <Stack spacing={1}>
    <BooleanOption
      label='¿Usted es apoderado, operador o empleado de otra Corredora de Bolsa?'
      name='isBrokerageEmployee'
    />
    <Divider />
    <BooleanOption
      label={(
        '¿Usted es o ha sido una Persona Expuesta Políticamente (PEP) '
        + 'o posee una relación sustancial con una de ellas?'
      )}
      name='isPoliticallyExposed'
    />
    <Divider />
    <BooleanOption
      label='¿Usted es un Inversionista Institucional NCG Nº410?'
      name='isInstitutionalInvestor'
    />
    <Divider />
    <BooleanOption
      label='¿Usted tiene inversiones financieras superiores a 2.000 Unidades de Fomento?'
      name='declaresLargeInvestments'
    />
    <Divider />
    <BooleanOption
      label='¿Usted es ciudadano estadounidense (US citizen)?'
      name='isUsCitizen'
    />
  </Stack>
)
