import { gql } from '@apollo/client'

export type InvestorProfile = {
  id: string
  declaresLargeInvestments: boolean
  isBrokerageEmployee: boolean
  isInstitutionalInvestor: boolean
  isPoliticallyExposed: boolean
  isUsCitizen: boolean
}

export const INVESTOR_PROFILE_FIELDS = gql`
  fragment InvestorProfileFields on InvestorProfile {
    id
    declaresLargeInvestments
    isBrokerageEmployee
    isInstitutionalInvestor
    isPoliticallyExposed
    isUsCitizen
  }
`

export type InvestorProfileVars = Record<string, never>

export type InvestorProfileData = {
  investorProfile: InvestorProfile
}

export const INVESTOR_PROFILE_QUERY = gql`
  ${INVESTOR_PROFILE_FIELDS}
  query InvestorProfile {
    investorProfile {
      ...InvestorProfileFields
    }
  }
`

export type UpdateInvestorProfileVars = {
  declaresLargeInvestments: boolean
  isBrokerageEmployee: boolean
  isInstitutionalInvestor: boolean
  isPoliticallyExposed: boolean
  isUsCitizen: boolean
}

export type UpdateInvestorProfileData = {
  updateInvestorProfile: string
}

export const UPDATE_INVESTOR_PROFILE_MUTATION = gql`
  mutation UpdateInvestorProfile(
    $declaresLargeInvestments: Boolean!
    $isBrokerageEmployee: Boolean!
    $isInstitutionalInvestor: Boolean!
    $isPoliticallyExposed: Boolean!
    $isUsCitizen: Boolean!
  ) {
    updateInvestorProfile(
      input: {
        declaresLargeInvestments: $declaresLargeInvestments
        isBrokerageEmployee: $isBrokerageEmployee
        isInstitutionalInvestor: $isInstitutionalInvestor
        isPoliticallyExposed: $isPoliticallyExposed
        isUsCitizen: $isUsCitizen
      }
    )
  }
`
