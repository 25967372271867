import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Collapse,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { RepresentativeIdentityForm } from './representative_identity_form'
import { ButtonContainer, ButtonsContainer, ErrorDisplay, Loading } from '../../../components'
import {
  BaseIdentityFields,
  CivilStateFields,
  FundsManagerRelationshipFields,
  PhoneNumberFields,
  baseIdentityValidationSchema,
  civilStateValidationSchema,
  fundsManagerRelationshipValidationSchema,
  getBaseIdentityInitialValues,
  getCivilStateInitialValues,
  getFundsManagerRelationshipInitialValues,
  getPhoneNumberInitialValues,
  phoneNumberValidationSchema,
} from '../../../forms'
import {
  CREATE_PERSON_LEGAL_IDENTITY_MUTATION,
  LEGAL_IDENTITIES_QUERY,
  PHONE_NUMBER_QUERY,
  UPDATE_PERSON_LEGAL_IDENTITY_MUTATION,
} from '../../../queries'
import { setFormError, toISO8601Date, translateError } from '../../../services'
import {
  SpouseIdentityForm,
  getSpouseIdentityFormValues,
  getSpouseIdentityMutationVariables,
  spouseIdentityValidationSchema,
} from '../spouse_identity_form'

import type {
  BaseIdentityFormValues,
  CivilStateFormValues,
  FundsManagerRelationshipFormValues,
  PhoneNumberFormValues,
} from '../../../forms'
import type {
  CreatePersonLegalIdentityData,
  CreatePersonLegalIdentityVars,
  LegalIdentitiesData,
  LegalIdentitiesVars,
  LegalIdentity,
  PhoneNumber,
  PhoneNumberData,
  PhoneNumberVars,
  UpdatePersonLegalIdentityData,
  UpdatePersonLegalIdentityVars,
} from '../../../queries'
import type { SpouseIdentityFormValues } from '../spouse_identity_form'
import type { FormikProps } from 'formik'
import type { ExecutionResult } from 'graphql'

type FormValues =
  {
    id?: string
    spouseIdentity: SpouseIdentityFormValues
  }
  & BaseIdentityFormValues
  & CivilStateFormValues
  & PhoneNumberFormValues
  & FundsManagerRelationshipFormValues

const getInitialValues = (
  legalIdentity?: LegalIdentity,
  phoneNumber?: PhoneNumber,
): FormValues => ({
  id: legalIdentity?.id || '',
  spouseIdentity: getSpouseIdentityFormValues(legalIdentity?.spouseIdentity),
  ...getBaseIdentityInitialValues(legalIdentity),
  ...getCivilStateInitialValues(legalIdentity),
  ...getPhoneNumberInitialValues(phoneNumber),
  ...getFundsManagerRelationshipInitialValues(legalIdentity?.fundsManagerRelationship),
})

const getMutationVariables = (data: FormValues): UpdatePersonLegalIdentityVars => ({
  id: data.id || '',
  birthdate: toISO8601Date(data.birthdate),
  familyName: data.familyName,
  givenName: data.givenName,
  identificationNumber: data.identificationNumber || '',
  nationality: data.nationality,
  civilState: data.civilState,
  spouseIdentity: data.civilState === 'MARRIED'
    ? getSpouseIdentityMutationVariables(data.spouseIdentity)
    : undefined,
  fundsManagerRelationship: data.fundsManagerRelationship,
  primaryPhoneNumber: data.primaryPhoneNumber,
  secondaryPhoneNumber: data.secondaryPhoneNumber || '',
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    id: Yup.string(),
    spouseIdentity: spouseIdentityValidationSchema
      .when('civilState', {
        is: 'MARRIED',
        then: (schema) => schema,
        otherwise: () => Yup.object(),
      }),
    ...baseIdentityValidationSchema.fields,
    ...civilStateValidationSchema.fields,
    ...phoneNumberValidationSchema.fields,
    ...fundsManagerRelationshipValidationSchema.fields,
  })

const StepForm = ({
  errors,
  isSubmitting,
  isValid,
  setFieldValue,
  status,
  submitForm,
  touched,
  values,
}: FormikProps<FormValues>) => (
  <Form>
    <BaseIdentityFields />
    <CivilStateFields />
    <Collapse in={values.civilState == 'MARRIED'}>
      <SpouseIdentityForm
        spouseIdentity={values.spouseIdentity}
        setSpouseIdentity={(values) => setFieldValue('spouseIdentity', values)}
      />
      {Boolean(errors.spouseIdentity) && (
        <ErrorDisplay
          errorMsg={touched.spouseIdentity
            ? 'La información del cónyuge contiene errores'
            : 'Debe agregar la información de cónyuge'}
          my={1}
        />
      )}
    </Collapse>
    <PhoneNumberFields />
    <FundsManagerRelationshipFields />
    <RepresentativeIdentityForm />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      my={1}
    />
    <ButtonsContainer sx={{ my: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type LegalIdentityStepProps = {
  handleNext: () => void
}

export const LegalIdentityStep = ({
  handleNext,
}: LegalIdentityStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const { loading: legalIdentitiesLoading, data: legalIdentitiesData } =
    useQuery<LegalIdentitiesData, LegalIdentitiesVars>(LEGAL_IDENTITIES_QUERY)

  const legalIdentity = legalIdentitiesData?.legalIdentities[0]

  const { loading: phoneNumberLoading, data: phoneNumberData }
    = useQuery<PhoneNumberData, PhoneNumberVars>(PHONE_NUMBER_QUERY)

  const [createLegalIdentityStep] =
    useMutation<CreatePersonLegalIdentityData, CreatePersonLegalIdentityVars>(
      CREATE_PERSON_LEGAL_IDENTITY_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          LEGAL_IDENTITIES_QUERY,
          PHONE_NUMBER_QUERY,
        ],
      })

  const [updateLegalIdentityStep] =
    useMutation<UpdatePersonLegalIdentityData, UpdatePersonLegalIdentityVars>(
      UPDATE_PERSON_LEGAL_IDENTITY_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          LEGAL_IDENTITIES_QUERY,
          PHONE_NUMBER_QUERY,
        ],
      })

  const handleResponse = (responses: (string | undefined)[], response: ExecutionResult) => {
    if (responses.every((response) => response === 'OK!')) {
      return handleNext()
    }

    setFormError(formRef, translateError(response))
  }

  const handleSubmit = async(values: FormValues) => {
    const variables = getMutationVariables(values)

    if (variables.id) {
      const response = await updateLegalIdentityStep({ variables })
      handleResponse(
        [response.data?.updateLegalIdentity, response.data?.updatePhoneNumber],
        response,
      )
    } else {
      const response = await createLegalIdentityStep({ variables })
      handleResponse(
        [response.data?.createLegalIdentity, response.data?.updatePhoneNumber],
        response,
      )
    }
  }

  return (legalIdentitiesLoading || phoneNumberLoading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={esLocale}
      >
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(
            legalIdentity,
            phoneNumberData?.phoneNumber,
          )}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => <StepForm {...props} />}
        </Formik>
      </LocalizationProvider>
    </React.Fragment>
  )
}
