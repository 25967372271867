import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Collapse,
  Divider,
  Stack,
  Tooltip,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  ErrorDisplay,
  Loading,
} from '../../../components'
import {
  BaseIdentityFields,
  CivilStateFields,
  FundsManagerRelationshipFields,
  baseIdentityValidationSchema,
  civilStateValidationSchema,
  fundsManagerRelationshipValidationSchema,
  getBaseIdentityInitialValues,
  getCivilStateInitialValues,
  getFundsManagerRelationshipInitialValues,
} from '../../../forms'
import {
  CREATE_LEGAL_IDENTITY_MUTATION,
  LEGAL_IDENTITIES_QUERY,
} from '../../../queries'
import { setFormError, toISO8601Date, translateError } from '../../../services'
import { PersonIdentityDisplay } from '../person_identity_display'
import {
  SpouseIdentityForm,
  getSpouseIdentityFormValues,
  getSpouseIdentityMutationVariables,
  spouseIdentityValidationSchema,
} from '../spouse_identity_form'

import type {
  BaseIdentityFormValues,
  CivilStateFormValues,
  FundsManagerRelationshipFormValues,
} from '../../../forms'
import type {
  CreateLegalIdentityData,
  CreateLegalIdentityVars,
  LegalIdentitiesData,
  LegalIdentitiesVars,
} from '../../../queries'
import type { SpouseIdentityFormValues } from '../spouse_identity_form'
import type { FormikHelpers, FormikProps } from 'formik'

type FormValues =
  { spouseIdentity: SpouseIdentityFormValues }
  & BaseIdentityFormValues
  & CivilStateFormValues
  & FundsManagerRelationshipFormValues

const initialValues: FormValues = ({
  spouseIdentity: getSpouseIdentityFormValues(),
  ...getBaseIdentityInitialValues(),
  ...getCivilStateInitialValues(),
  ...getFundsManagerRelationshipInitialValues(),
})

const getMutationVariables = (data: FormValues): CreateLegalIdentityVars => ({
  birthdate: toISO8601Date(data.birthdate),
  familyName: data.familyName,
  givenName: data.givenName,
  identificationNumber: data.identificationNumber || '',
  nationality: data.nationality,
  civilState: data.civilState,
  spouseIdentity: data.civilState === 'MARRIED'
    ? getSpouseIdentityMutationVariables(data.spouseIdentity)
    : undefined,
  fundsManagerRelationship: data.fundsManagerRelationship,
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    spouseIdentity: spouseIdentityValidationSchema
      .when('civilState', {
        is: 'MARRIED',
        then: (schema) => schema,
        otherwise: () => Yup.object(),
      }),
    ...baseIdentityValidationSchema.fields,
    ...civilStateValidationSchema.fields,
    ...fundsManagerRelationshipValidationSchema.fields,
  })

const LegalIdentityForm = ({
  errors,
  isSubmitting,
  isValid,
  setFieldValue,
  status,
  submitForm,
  touched,
  values,
}: FormikProps<FormValues>) => (
  <Form style={{ width: '100%' }}>
    <BaseIdentityFields />
    <CivilStateFields />
    <Collapse in={values.civilState == 'MARRIED'}>
      <SpouseIdentityForm
        spouseIdentity={values.spouseIdentity}
        setSpouseIdentity={(values) => setFieldValue('spouseIdentity', values)}
      />
      {Boolean(errors.spouseIdentity) && (
        <ErrorDisplay
          errorMsg={touched.spouseIdentity
            ? 'La información del cónyuge contiene errores'
            : 'Debe agregar la información de cónyuge'}
          my={1}
        />
      )}
    </Collapse>
    <FundsManagerRelationshipFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      my={1}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Agregar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type BusinessLegalIdentitiesStepProps = {
  handleNext: () => void
  handleBack: () => void
}

export const BusinessLegalIdentitiesStep = ({
  handleNext,
  handleBack,
}: BusinessLegalIdentitiesStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [formOpen, setFormOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const { loading, data } =
    useQuery<LegalIdentitiesData, LegalIdentitiesVars>(LEGAL_IDENTITIES_QUERY)

  const legalIdentities = data?.legalIdentities || []

  const [createLegalIdentity] =
    useMutation<CreateLegalIdentityData, CreateLegalIdentityVars>(
      CREATE_LEGAL_IDENTITY_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          LEGAL_IDENTITIES_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    const variables = getMutationVariables(values)
    const response = await createLegalIdentity({ variables })

    if (response.data?.createLegalIdentity === 'OK!') {
      setFormOpen(false)
      resetForm()
      return
    }

    setFormError(formRef, translateError(response))
  }

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Stack
        spacing={2}
        mt={1}
      >
        {legalIdentities.map((legalIdentity, index) => (
          <React.Fragment key={index}>
            <PersonIdentityDisplay
              personIdentity={legalIdentity}
              identityType='LEGAL_IDENTITY'
            />
            {(index < legalIdentities.length - 1) && <Divider />}
          </React.Fragment>
        ))}
        <Collapse in={formOpen}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => <LegalIdentityForm {...props} />}
            </Formik>
          </LocalizationProvider>
        </Collapse>
        <Collapse in={!formOpen}>
          {(legalIdentities.length >= 2) ? (
            <Tooltip title='Pueden haber máximo 2 representantes legales'>
              <span>
                <Button
                  fullWidth
                  disabled
                  variant='contained'
                >
                  Agregar representante legal
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              fullWidth
              onClick={() => setFormOpen(true)}
              variant={legalIdentities.length > 0 ? 'outlined' : 'contained'}
            >
              Agregar representante legal
            </Button>
          )}
        </Collapse>
      </Stack>
      <ButtonsContainer sx={{ my: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            onClick={handleBack}
            variant='outlined'
          >
            Atrás
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={data && data.legalIdentities.length < 1}
            onClick={() => (formOpen) ? openDialog() : handleNext()}
            variant='contained'
          >
            Continuar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='¿Continuar sin agregar el representante legal?'
      >
        <ButtonsContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              onClick={closeDialog}
              variant='outlined'
              size='large'
            >
              Volver
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              onClick={handleNext}
              variant='contained'
              size='large'
            >
              Continuar
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}
