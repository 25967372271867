import { gql } from '@apollo/client'

import { FUNDS_MANAGER_RELATIONSHIP_FIELDS } from './funds_manager_relationships'

import type { FundsManagerRelationship } from './funds_manager_relationships'

export type SpouseIdentityInput = {
  givenName: string
  familyName: string
  birthdate: string
  identificationNumber: string
  nationality: string
  fundsManagerRelationship: string
}

export type SpouseIdentity = {
  id: string
  givenName: string
  familyName: string
  birthdate: string
  identificationNumber: string
  nationality: string
  fundsManagerRelationship: FundsManagerRelationship
}

export const SPOUSE_IDENTITY_FIELDS = gql`
  ${FUNDS_MANAGER_RELATIONSHIP_FIELDS}
  fragment SpouseIdentityFields on SpouseIdentity {
    id
    givenName
    familyName
    birthdate
    identificationNumber
    nationality
    fundsManagerRelationship {
      ...FundsManagerRelationshipFields
    }
  }
`
