import { gql } from '@apollo/client'

import { FUNDS_MANAGER_RELATIONSHIP_FIELDS } from './funds_manager_relationships'
import { SPOUSE_IDENTITY_FIELDS } from './spouse_identities'

import type { FundsManagerRelationship } from './funds_manager_relationships'
import type { SpouseIdentity, SpouseIdentityInput } from './spouse_identities'

export type CivilState = 'SINGLE' | 'DIVORCED' | 'CIVIL_PARTNER' | 'MARRIED' | 'WIDOWER'

export const CIVIL_STATE_LABELS: { [key in CivilState]: string } = {
  'SINGLE': 'Soltero',
  'DIVORCED': 'Divorciado',
  'CIVIL_PARTNER': 'Conviviente civil',
  'MARRIED': 'Casado',
  'WIDOWER': 'Viudo',
}

export const civilStateStr = (civilState?: CivilState) => (
  civilState ? CIVIL_STATE_LABELS[civilState] : 'Sin declarar'
)

export type LegalIdentity = {
  birthdate: string
  civilState?: CivilState
  familyName: string
  fundsManagerRelationship?: FundsManagerRelationship
  givenName: string
  id: string
  identificationNumber: string
  nationality: string
  spouseIdentity?: SpouseIdentity
}

export const LEGAL_IDENTITY_FIELDS = gql`
  ${SPOUSE_IDENTITY_FIELDS}
  ${FUNDS_MANAGER_RELATIONSHIP_FIELDS}
  fragment LegalIdentityFields on LegalIdentity {
    birthdate
    civilState
    familyName
    fundsManagerRelationship {
      ...FundsManagerRelationshipFields
    }
    givenName
    id
    identificationNumber
    nationality
    spouseIdentity {
      ...SpouseIdentityFields
    }
  }
`

export type LegalIdentitiesVars = Record<string, never>

export type LegalIdentitiesData = {
  legalIdentities: LegalIdentity[]
}

export const LEGAL_IDENTITIES_QUERY = gql`
  ${LEGAL_IDENTITY_FIELDS}
  query LegalIdentities {
    legalIdentities {
      ...LegalIdentityFields
    }
  }
`

export type CreateLegalIdentityVars = {
  birthdate: string
  civilState?: string
  familyName: string
  fundsManagerRelationship?: string
  givenName: string
  identificationNumber: string
  nationality: string
  spouseIdentity?: SpouseIdentityInput
}

export type CreateLegalIdentityData = {
  createLegalIdentity: string
}

export const CREATE_LEGAL_IDENTITY_MUTATION = gql`
  mutation CreateLegalIdentity(
    $birthdate: ISO8601Date!
    $civilState: String
    $familyName: String!
    $fundsManagerRelationship: String
    $givenName: String!
    $identificationNumber: String!
    $nationality: String!
    $spouseIdentity: SpouseIdentityInput
  ) {
    createLegalIdentity(
      input: {
        birthdate: $birthdate
        civilState: $civilState
        familyName: $familyName
        fundsManagerRelationship: $fundsManagerRelationship
        givenName: $givenName
        identificationNumber: $identificationNumber
        nationality: $nationality
        spouseIdentity: $spouseIdentity
      }
    )
  }
`

export type CreatePersonLegalIdentityVars = CreateLegalIdentityVars & {
  primaryPhoneNumber: string
  secondaryPhoneNumber?: string
}

export type CreatePersonLegalIdentityData = {
  createLegalIdentity: string
  updatePhoneNumber: string
}

export const CREATE_PERSON_LEGAL_IDENTITY_MUTATION = gql`
  mutation CreatePersonLegalIdentity(
    $givenName: String!
    $familyName: String!
    $birthdate: ISO8601Date!
    $nationality: String!
    $civilState: String!
    $identificationNumber: String!
    $fundsManagerRelationship: String!
    $spouseIdentity: SpouseIdentityInput
    $primaryPhoneNumber: String!
    $secondaryPhoneNumber: String
  ) {
    createLegalIdentity(input: {
      givenName: $givenName
      familyName: $familyName
      birthdate: $birthdate
      nationality: $nationality
      civilState: $civilState
      identificationNumber: $identificationNumber
      fundsManagerRelationship: $fundsManagerRelationship
      spouseIdentity: $spouseIdentity
    })
    updatePhoneNumber(input: {
      primary: $primaryPhoneNumber
      secondary: $secondaryPhoneNumber
    })
  }
`

export type UpdateLegalIdentityVars = {
  birthdate: string
  civilState?: string
  familyName: string
  fundsManagerRelationship?: string
  givenName: string
  id: string
  identificationNumber: string
  nationality: string
  spouseIdentity?: SpouseIdentityInput
}

export type UpdateLegalIdentityData = {
  updateLegalIdentity: string
}

export const UPDATE_LEGAL_IDENTITY_MUTATION = gql`
  mutation UpdateLegalIdentity(
    $birthdate: ISO8601Date!
    $civilState: String
    $familyName: String!
    $fundsManagerRelationship: String
    $givenName: String!
    $id: ID!
    $identificationNumber: String!
    $nationality: String!
    $spouseIdentity: SpouseIdentityInput
  ) {
    updateLegalIdentity(
      input: {
        birthdate: $birthdate
        civilState: $civilState
        familyName: $familyName
        fundsManagerRelationship: $fundsManagerRelationship
        givenName: $givenName
        id: $id
        identificationNumber: $identificationNumber
        nationality: $nationality
        spouseIdentity: $spouseIdentity
      }
    )
  }
`

export type UpdatePersonLegalIdentityVars = UpdateLegalIdentityVars & {
  primaryPhoneNumber: string
  secondaryPhoneNumber?: string
}

export type UpdatePersonLegalIdentityData = {
  updateLegalIdentity: string
  updatePhoneNumber: string
}

export const UPDATE_PERSON_LEGAL_IDENTITY_MUTATION = gql`
  mutation UpdatePersonLegalIdentity(
    $birthdate: ISO8601Date!
    $civilState: String
    $familyName: String!
    $fundsManagerRelationship: String
    $givenName: String!
    $id: ID!
    $identificationNumber: String!
    $nationality: String!
    $spouseIdentity: SpouseIdentityInput
    $primaryPhoneNumber: String!
    $secondaryPhoneNumber: String
  ) {
    updateLegalIdentity(input: {
      birthdate: $birthdate
      civilState: $civilState
      familyName: $familyName
      fundsManagerRelationship: $fundsManagerRelationship
      givenName: $givenName
      id: $id
      identificationNumber: $identificationNumber
      nationality: $nationality
      spouseIdentity: $spouseIdentity
    })
    updatePhoneNumber(input: {
        primary: $primaryPhoneNumber
        secondary: $secondaryPhoneNumber
    })
  }
`

export type DeleteLegalIdentityVars = {
  legalIdentityId: string
}

export type DeleteLegalIdentityData = {
  deleteLegalIdentity: string
}

export const DELETE_LEGAL_IDENTITY_MUTATION = gql`
  mutation DeleteLegalIdentity(
    $legalIdentityId: ID!
  ) {
    deleteLegalIdentity(
      input: {
        legalIdentityId: $legalIdentityId
      }
    )
  }
`
