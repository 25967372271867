import { gql } from '@apollo/client'

export type QuoteSymbol = 'BTC' | 'UF'

export type ConversionCurrency = 'USD' | 'CLP'

export type AccountStatus =
  'IDENTITY_PENDING_REVIEW'
  | 'IDENTITY_PENDING_CHANGES'
  | 'IDENTITY_REJECTED'
  | 'IDENTITY_VERIFIED'
  | 'IDENTITY_UNVERIFIED'
  | 'EMAIL_UNCONFIRMED'
  | 'UNKNOWN'

export type AccountInformationVars = Record<string, never>

export type AccountInformationData = {
  accountInformation: {
    accountStatus: AccountStatus
    email: string
    fundsManagerName?: string
    isBusiness: boolean
    nickName: string
    lastVerificationUpdate: string
    quoteSymbol: QuoteSymbol
    conversionCurrency: ConversionCurrency
  }
}

export const ACCOUNT_INFORMATION_QUERY = gql`
  query AccountInformation {
    accountInformation {
      accountStatus
      email
      fundsManagerName
      isBusiness
      nickName
      lastVerificationUpdate
      quoteSymbol
      conversionCurrency
    }
  }
`
