import countryList from 'iso3166-2-db/i18n/dispute/UN/es.json'

type Region = {
  iso: string
  name: string
}

type Country = {
  iso: string
  name: string
  regions: Region[]
}

const BANNED_COUNTRY_CODES = [
  'US', // United States
  'BY', // Belarus
  'CU', // Cuba
  'CD', // Democratic Republic of Congo
  'IR', // Iran
  'IQ', // Iraq
  'KP', // North Korea
  'SD', // Sudan
  'SS', // South Sudan
  'SY', // Syria
  'ZW', // Zimbabwe
]

const FILTERED_COUNTRIES: Country[] = (
  Object
    .values(countryList)
    .filter((country) => !BANNED_COUNTRY_CODES.includes(country.iso))
    .sort((a, b) => a.name.localeCompare(b.name))
)

export const SORTED_COUNTRIES: Country[] = (
  [FILTERED_COUNTRIES.find((country) => country.name === 'Chile')!, ...FILTERED_COUNTRIES]
)

export const sortedRegions = (countryCode: string): Region[] => {
  const country = findCountryByCode(countryCode)
  const regions = country?.regions || []

  return regions
    .filter((region) => !!region.iso)
    .sort((a, b) => a.name.localeCompare(b.name))
}

export const findCountryByCode = (countryCode: string): Country | undefined => (
  SORTED_COUNTRIES.find(({ iso }) => iso === countryCode)
)

export const findRegionByCode = (regionCode: string, country?: Country): Region | undefined => (
  country?.regions.find(({ iso }) => iso === regionCode)
)
