import { gql } from '@apollo/client'

export type EmploymentSituation = 'EMPLOYEE' | 'INDEPENDENT' | 'RETIRED' | 'UNEMPLOYED' | 'STUDENT'

export const EMPLOYMENT_SITUATION_LABELS: { [key in EmploymentSituation]: string } = {
  'EMPLOYEE': 'Empleado',
  'STUDENT': 'Estudiante',
  'INDEPENDENT': 'Independiente',
  'RETIRED': 'Jubilado',
  'UNEMPLOYED': 'Sin empleo',
}

export type OccupationProfile = {
  id: string
  employmentSituation: EmploymentSituation
  profession: string
  company?: string
  companyIdentificationNumber?: string
  jobTitle?: string
}

export const OCCUPATION_PROFILE_FIELDS = gql`
  fragment OccupationProfileFields on OccupationProfile {
    id
    employmentSituation
    profession
    company
    companyIdentificationNumber
    jobTitle
  }
`

export type OccupationProfileVars = Record<string, never>

export type OccupationProfileData = {
  occupationProfile: OccupationProfile
}

export const OCCUPATION_PROFILE_QUERY = gql`
  ${OCCUPATION_PROFILE_FIELDS}
  query OccupationProfile {
    occupationProfile {
      ...OccupationProfileFields
    }
  }
`

export type UpdateOccupationProfileVars = {
  employmentSituation: string
  profession: string
  company?: string
  companyIdentificationNumber?: string
  jobTitle?: string
}

export type UpdateOccupationProfileData = {
  updateOccupationProfile: string
}

export const UPDATE_OCCUPATION_PROFILE_MUTATION = gql`
  mutation UpdateOccupationProfile(
    $employmentSituation: String!
    $profession: String!
    $company: String
    $companyIdentificationNumber: String
    $jobTitle: String
  ) {
    updateOccupationProfile(
      input: {
        employmentSituation: $employmentSituation
        profession: $profession
        company: $company
        companyIdentificationNumber: $companyIdentificationNumber
        jobTitle: $jobTitle
      }
    )
  }
`
