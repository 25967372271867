import { timeFormat, timeParse } from 'd3-time-format'

const locale = 'es-CL'

export const numberFormatter = (digits: number) => Intl.NumberFormat(locale, {
  minimumFractionDigits: digits,
  maximumFractionDigits: digits,
})

export const percentFormatter = Intl.NumberFormat(locale, {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const humanTimeFormatter = (remainingSeconds: number) => {
  const days = Math.floor(remainingSeconds / (60 * 60 * 24))
  const hours = Math.floor((remainingSeconds / (60 * 60)) % 24)
  const minutes = Math.floor((remainingSeconds / 60) % 60)
  const seconds = Math.floor((remainingSeconds) % 60)

  /* TODO: convert to i18n */
  const daysStr = days === 1 ? '1 día' : `${days} días`
  const hoursStr = hours === 1 ? '1 hora' : `${hours} horas`
  const minutesStr = minutes === 1 ? '1 minuto' : `${minutes} minutos`
  const secondsStr = seconds === 1 ? '1 segundo' : `${seconds} segundos`

  if (days > 0) {
    return `${daysStr} y ${hoursStr}`
  } else if (hours > 0) {
    return `${hoursStr} y ${minutesStr}`
  } else if (minutes > 0) {
    return `${minutesStr} y ${secondsStr}`
  } else {
    return secondsStr
  }
}

export const localizeISO8601 = (dateStr: string) => (
  new Date(dateStr).toLocaleString()
)

export const fromISO8601Date = (dateStr?: string) => (
  dateStr ? timeParse('%Y-%m-%d')(dateStr) : null
)

export const toISO8601Date = (date?: Date | null) => {
  if (!date) {
    throw new Error('Date cannot be empty.')
  }

  return timeFormat('%Y-%m-%d')(date)
}

export const toMaybeISO8601Date = (date?: Date | null) => (
  date ? timeFormat('%Y-%m-%d')(date) : null
)

export const fromISO8601DateTime = (dateStr?: string) => (
  dateStr ? new Date(dateStr) : null
)

export const toISO8601DateTime = (date?: Date | null) => (
  date ? date.toISOString() : ''
)

export const booleanToString = (value?: boolean) => {
  if (typeof value === 'undefined') {
    return ''
  }

  return value ? 'true' : 'false'
}

export const stringToBoolean = (value: string) => value === 'true'
