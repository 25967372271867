import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Select } from 'formik-mui'
import { MobileDatePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import {
  BackdropLoading,
  ButtonContainer,
  ButtonsContainer,
  DocumentUpload,
  ErrorDisplay,
  Loading,
} from '../../components'
import {
  CREATE_LEGAL_IDENTITY_PROOF_MUTATION,
  LEGAL_IDENTITY_PROOFS_QUERY,
  POI_CONTENT_TYPE_LABELS,
  POI_DOCUMENT_TYPE_LABELS,
  UPDATE_LEGAL_IDENTITY_PROOF_MUTATION,
} from '../../queries'
import {
  hasValidContentType,
  hasValidSize,
  minPoiDate,
  setFormError,
  toISO8601Date,
  translateError,
} from '../../services'
import { DocumentDisplay } from '../document_display'

import type {
  CreateLegalIdentityProofData,
  CreateLegalIdentityProofVars,
  LegalIdentityProofView,
  LegalIdentityProofsData,
  LegalIdentityProofsVars,
  PersonDocumentType,
  UpdateLegalIdentityProofData,
  UpdateLegalIdentityProofVars,
} from '../../queries'

const PERSON_DOCUMENT_TYPES = [
  {
    label: 'Cédula de identidad',
    value: 'ID_CARD',
  },
  {
    label: 'Pasaporte',
    value: 'PASSPORT',
  },
]

type FormValues = {
  documentType: string
  expirationDate: Date | null
  frontImage: File | undefined
  backImage?: File | undefined
}

const initialValues: FormValues = {
  documentType: '',
  expirationDate: null,
  frontImage: undefined,
  backImage: undefined,
}

const validationSchema =
  Yup.object().shape({
    documentType: Yup.string()
      .required('Este campo es obligatorio'),
    expirationDate: Yup.date()
      .typeError('La fecha ingresada es inválida')
      .min(minPoiDate(), 'La fecha ingresada debe ser en el futuro')
      .required('Este campo es obligatorio'),
    frontImage: Yup.mixed()
      .required('Este campo es obligatorio')
      .test('fileType', 'Debes subir un archivo PDF/HEIC/JPG/PNG', hasValidContentType)
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 8 MB', hasValidSize),
    backImage: Yup.mixed()
      .when('documentType', {
        is: 'ID_CARD',
        then: Yup.mixed().required('Este campo es obligatorio'),
      })
      .test('fileType', 'Debes subir un archivo PDF/HEIC/JPG/PNG', hasValidContentType)
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 8 MB', hasValidSize),
  })

type StepFormProps = FormikProps<FormValues> & {
  handleBack: () => void
  showWaitingMessage: boolean
}

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  values,
  submitForm,
  handleBack,
  showWaitingMessage,
}: StepFormProps) => {
  const isIdCard = values.documentType === 'ID_CARD'

  return (
    <Form>
      {showWaitingMessage && (
        <BackdropLoading
          message={isIdCard ? (
            'Por favor espera mientras cargamos tus documentos...'
          ) : (
            'Por favor espera mientras cargamos tu documento...'
          )}
        />
      )}
      <Stack spacing={2}>
        <Field
          required
          name='documentType'
          type='text'
          label='Tipo de documento'
          component={Select}
        >
          {PERSON_DOCUMENT_TYPES.map(({ label, value }) => (
            <MenuItem
              key={value}
              value={value}
            >
              {label}
            </MenuItem>
          ))}
        </Field>
        <Field
          required
          name='expirationDate'
          type='date'
          label='Fecha de expiración'
          component={MobileDatePicker}
          minDate={minPoiDate()}
          views={['year', 'month', 'day']}
          inputFormat='yyyy/MM/dd'
          toolbarTitle='Ingresa la fecha de expiración del documento'
          closeOnSelect
        />
        {isIdCard && <Divider>Imagen frontal</Divider>}
        <DocumentUpload
          name='frontImage'
          imageAlt='Imagen Frontal'
          contentTypes={Object.keys(POI_CONTENT_TYPE_LABELS)}
          allowWebcam
        />
        {isIdCard && (
          <React.Fragment>
            <Divider>Imagen trasera</Divider>
            <DocumentUpload
              name='backImage'
              imageAlt='Imagen Trasera'
              contentTypes={Object.keys(POI_CONTENT_TYPE_LABELS)}
              allowWebcam
            />
          </React.Fragment>
        )}
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={2}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting}
            onClick={handleBack}
            variant='outlined'
          >
            Atrás
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='primary'
          >
            {isSubmitting ? 'Cargando...' : 'Continuar'}
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type LegalIdentityProofsDisplayProps = {
  handleBack: () => void
  handleNext: () => void
  handleShowForm: () => void
  legalIdentityProofs: LegalIdentityProofView[]
}

const LegalIdentityProofsDisplay = ({
  handleBack,
  handleNext,
  handleShowForm,
  legalIdentityProofs,
}: LegalIdentityProofsDisplayProps) => (
  <List>
    {legalIdentityProofs.map((legalIdentityProof, index) => (
      <React.Fragment key={index}>
        <ListItem>
          <DocumentDisplay
            contentType={legalIdentityProof.contentType}
            documentType={legalIdentityProof.documentType}
            storageUrl={legalIdentityProof.storageUrl}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary={POI_DOCUMENT_TYPE_LABELS[legalIdentityProof.documentType]}
            primaryTypographyProps={{ textAlign: 'center' }}
          />
        </ListItem>
      </React.Fragment>
    ))}
    <Button
      onClick={handleShowForm}
      variant='outlined'
      sx={{ mt: 3, mb: 2 }}
      fullWidth
    >
      Resubir nuevos documentos
    </Button>
    <ButtonsContainer sx={{ my: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleNext}
          variant='contained'
          color='primary'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </List>
)

type LegalIdentityProofsStepProps = {
  handleBack: () => void
  handleNext: () => void
}

export const PersonLegalIdentityProofsStep = ({
  handleBack,
  handleNext,
}: LegalIdentityProofsStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [showForm, setShowForm] = React.useState(false)
  const [showWaitingMessage, setShowWaitingMessage] = React.useState(false)

  const { loading: proofLoading, data: proofData } =
    useQuery<LegalIdentityProofsData, LegalIdentityProofsVars>(LEGAL_IDENTITY_PROOFS_QUERY)

  const legalIdentityProofs = proofData?.legalIdentityProofs || []
  const validProofsQuantity = () => {
    if (legalIdentityProofs.length === 0) {
      return false
    }

    const hasIdCard = legalIdentityProofs[0].documentType.startsWith('ID_CARD')
    if (hasIdCard && legalIdentityProofs.length === 1) {
      return false
    }

    return true
  }

  const [createLegalIdentityProof] =
    useMutation<CreateLegalIdentityProofData, CreateLegalIdentityProofVars>(
      CREATE_LEGAL_IDENTITY_PROOF_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateLegalIdentityProof] =
    useMutation<UpdateLegalIdentityProofData, UpdateLegalIdentityProofVars>(
      UPDATE_LEGAL_IDENTITY_PROOF_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          LEGAL_IDENTITY_PROOFS_QUERY,
        ],
      })

  const uploadFile = async (
    documentType: PersonDocumentType,
    expirationDate: Date | null,
    file: File | undefined,
  ) => {
    if (!file) {
      setFormError(formRef, 'No se encontró el archivo de origen.')
      return false
    }

    const response = await createLegalIdentityProof({
      variables: {
        contentType: file.type,
        documentType,
        expirationDate: toISO8601Date(expirationDate),
      },
    })

    const identityProofId = response.data?.createLegalIdentityProof.id || ''
    const storagePost = response.data?.createLegalIdentityProof.storagePost

    if (storagePost) {
      const form = new FormData()
      storagePost.fieldsKeys.forEach((key, index) => (
        form.append(key, storagePost.fieldsValues[index])
      ))
      form.append('file', file)
      const storageResponse = await fetch(storagePost.url, { method: 'POST', body: form })

      if (storageResponse.ok) {
        const response = await updateLegalIdentityProof({
          variables: {
            id: identityProofId,
          },
        })

        if (response.data?.updateLegalIdentityProof === 'OK!') {
          return true
        }

        setFormError(formRef, translateError(response))
        return false
      }

      setFormError(formRef, 'Ocurrió un error al subir tu archivo.')
      return false
    }

    setFormError(formRef, translateError(response))
    return false
  }

  const handleSubmit = async (values: FormValues) => {
    setShowWaitingMessage(true)
    let response = false
    if (values.documentType === 'ID_CARD') {
      const responses = await Promise.all([
        uploadFile('ID_CARD_FRONT', values.expirationDate, values.frontImage),
        uploadFile('ID_CARD_BACK', values.expirationDate, values.backImage),
      ])
      response = responses.reduce((acc, curr) => acc && curr)
    } else if (values.documentType === 'PASSPORT') {
      response = await uploadFile('PASSPORT', values.expirationDate, values.frontImage)
    }

    if (response) {
      handleNext()
    } else {
      setShowWaitingMessage(false)
    }
  }

  if (proofLoading) {
    return <Loading />
  }

  return (!showForm && validProofsQuantity()) ? (
    <LegalIdentityProofsDisplay
      handleBack={handleBack}
      handleNext={handleNext}
      handleShowForm={() => setShowForm(true)}
      legalIdentityProofs={legalIdentityProofs}
    />
  ) : (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <StepForm
            handleBack={handleBack}
            showWaitingMessage={showWaitingMessage}
            {...props}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}
