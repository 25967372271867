import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  BackdropLoading,
  ButtonContainer,
  ButtonsContainer,
  DocumentDisplay,
  DocumentUpload,
  ErrorDisplay,
  Loading,
} from '../../../components'
import {
  BUSINESS_DOCUMENT_TYPE_LABELS,
  CREATE_LEGAL_IDENTITY_PROOF_MUTATION,
  LEGAL_IDENTITY_PROOFS_QUERY,
  POI_DOCUMENT_TYPE_LABELS,
  UPDATE_LEGAL_IDENTITY_PROOF_MUTATION,
} from '../../../queries'
import {
  hasValidContentType,
  hasValidSize,
  setFormError,
  translateError,
} from '../../../services'

import type {
  CreateLegalIdentityProofData,
  CreateLegalIdentityProofVars,
  LegalIdentityProofView,
  LegalIdentityProofsData,
  LegalIdentityProofsVars,
  UpdateLegalIdentityProofData,
  UpdateLegalIdentityProofVars,
} from '../../../queries'
import type { FormikProps } from 'formik'

type FormValues = {
  document: File | undefined
}

const initialValues: FormValues = ({
  document: undefined,
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object({
    document: Yup.mixed()
      .required('Este campo es obligatorio')
      .test('fileType', 'Debes subir un archivo PDF/HEIC/JPG/PNG', hasValidContentType)
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 8 MB', hasValidSize),
  })

type StepFormProps = FormikProps<FormValues> & {
  handleBack: () => void
  showWaitingMessage: boolean
}

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  handleBack,
  showWaitingMessage,
}: StepFormProps) => (
  <Form>
    {showWaitingMessage && (
      <BackdropLoading message='Por favor espera mientras cargamos tu documento...' />
    )}
    <Stack
      spacing={2}
      mt={1}
    >
      <Typography textAlign='center'>
        {BUSINESS_DOCUMENT_TYPE_LABELS['COMPANY_CONSTITUTION']}:
      </Typography>
      <DocumentUpload
        name='document'
        imageAlt='Imagen del documento'
        contentTypes={['application/pdf']}
      />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          {isSubmitting ? 'Cargando...' : 'Continuar'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type LegalIdentityProofsDisplayProps = {
  handleBack: () => void
  handleNext: () => void
  handleShowForm: () => void
  legalIdentityProofs: LegalIdentityProofView[]
}

const LegalIdentityProofsDisplay = ({
  handleBack,
  handleNext,
  handleShowForm,
  legalIdentityProofs,
}: LegalIdentityProofsDisplayProps) => (
  <List>
    {legalIdentityProofs.map((legalIdentityProof, index) => (
      <React.Fragment key={index}>
        <ListItem>
          <DocumentDisplay
            contentType={legalIdentityProof.contentType}
            documentType={legalIdentityProof.documentType}
            storageUrl={legalIdentityProof.storageUrl}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary={POI_DOCUMENT_TYPE_LABELS[legalIdentityProof.documentType]}
            primaryTypographyProps={{ textAlign: 'center' }}
          />
        </ListItem>
      </React.Fragment>
    ))}
    <Button
      onClick={handleShowForm}
      variant='outlined'
      sx={{ mt: 3, mb: 2 }}
      fullWidth
    >
      Resubir nuevos documentos
    </Button>
    <ButtonsContainer sx={{ my: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleNext}
          variant='contained'
          color='primary'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </List>
)

type BusinessIdentityProofStepProps = {
  handleBack: () => void
  handleNext: () => void
}

export const BusinessIdentityProofStep = ({
  handleBack,
  handleNext,
}: BusinessIdentityProofStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [showForm, setShowForm] = React.useState(false)
  const [showWaitingMessage, setShowWaitingMessage] = React.useState(false)

  const { loading: proofLoading, data: proofData } =
    useQuery<LegalIdentityProofsData, LegalIdentityProofsVars>(LEGAL_IDENTITY_PROOFS_QUERY)

  const legalIdentityProofs = proofData?.legalIdentityProofs || []

  const [createLegalIdentityProof] =
    useMutation<CreateLegalIdentityProofData, CreateLegalIdentityProofVars>(
      CREATE_LEGAL_IDENTITY_PROOF_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateLegalIdentityProof] =
    useMutation<UpdateLegalIdentityProofData, UpdateLegalIdentityProofVars>(
      UPDATE_LEGAL_IDENTITY_PROOF_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          LEGAL_IDENTITY_PROOFS_QUERY,
        ],
      })

  const uploadFile = async (file: File | undefined) => {
    if (!file) {
      setFormError(formRef, 'No se encontró el archivo de origen.')
      return false
    }

    const response = await createLegalIdentityProof({
      variables: {
        contentType: file.type,
        documentType: 'COMPANY_CONSTITUTION',
        expirationDate: null,
      },
    })

    const identityProofId = response.data?.createLegalIdentityProof.id || ''
    const storagePost = response.data?.createLegalIdentityProof.storagePost

    if (storagePost) {
      const form = new FormData()
      storagePost.fieldsKeys.forEach((key, index) => (
        form.append(key, storagePost.fieldsValues[index])
      ))
      form.append('file', file)
      const storageResponse = await fetch(storagePost.url, { method: 'POST', body: form })

      if (storageResponse.ok) {
        await updateLegalIdentityProof({
          variables: {
            id: identityProofId,
          },
        })
        return true
      }

      setFormError(formRef, 'Ocurrió un error al subir tu archivo.')
      return false
    }

    setFormError(formRef, translateError(response))
    return false
  }

  const handleSubmit = async (values: FormValues) => {
    setShowWaitingMessage(true)
    const response = await uploadFile(values.document)

    if (response) {
      handleNext()
    } else {
      setShowWaitingMessage(false)
    }
  }

  if (proofLoading) {
    return <Loading />
  }

  return (!showForm && legalIdentityProofs.length > 0) ? (
    <LegalIdentityProofsDisplay
      handleBack={handleBack}
      handleNext={handleNext}
      handleShowForm={() => setShowForm(true)}
      legalIdentityProofs={legalIdentityProofs}
    />
  ) : (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <StepForm
          handleBack={handleBack}
          showWaitingMessage={showWaitingMessage}
          {...props}
        />
      )}
    </Formik>
  )
}
