import { gql } from '@apollo/client'

export type FundsManagerRelationshipKind =
  'PARTNER'
  | 'DIRECTOR'
  | 'WORKER'
  | 'MEMBER_OF_BUSINESS_GROUP'
  | 'OTHER_RELATIONSHIP'
  | 'NO_RELATIONSHIP'

export const FUNDS_MANAGER_RELATIONSHIP_LABELS: { [key in FundsManagerRelationshipKind]: string } = {
  'PARTNER': 'Socio',
  'DIRECTOR': 'Director',
  'WORKER': 'Trabajador',
  'MEMBER_OF_BUSINESS_GROUP': 'Miembro del grupo empresarial',
  'OTHER_RELATIONSHIP': 'Otra relación',
  'NO_RELATIONSHIP': 'Sin relación',
}

export const fundsManagerKindStr = (relationship?: FundsManagerRelationship) => (
  relationship ? FUNDS_MANAGER_RELATIONSHIP_LABELS[relationship.kind] : 'Sin declarar'
)

export type FundsManagerRelationship = {
  id: string
  kind: FundsManagerRelationshipKind
}

export const FUNDS_MANAGER_RELATIONSHIP_FIELDS = gql`
  fragment FundsManagerRelationshipFields on FundsManagerRelationship {
    id
    kind
  }
`
