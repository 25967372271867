import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Logout, Person, Settings } from '@mui/icons-material'
import {
  Avatar as BaseAvatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  alpha,
  styled,
} from '@mui/material'
import { navigate } from '@reach/router'

import { FirebaseContext } from '../contexts'
import { USER_LOGOUT_MUTATION } from '../queries'
import { clearCredential, getCredential } from '../services'

import type { UserLogoutData, UserLogoutVars } from '../queries'

const Avatar = styled(BaseAvatar)(({ theme }) => ({
  width: '2rem',
  height: '2rem',
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.text.secondary,
  backgroundColor: alpha(
    theme.palette.primary.main,
    theme.palette.action.selectedOpacity,
  ),
}))

export const AppNavElements = () => {
  const { analytics, logEvent } = React.useContext(FirebaseContext)

  const [loggingOut, setLoggingOut] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const [userLogout] = useMutation<UserLogoutData, UserLogoutVars>(USER_LOGOUT_MUTATION, {
    errorPolicy: 'all',
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    setLoggingOut(true)
    await userLogout()
    clearCredential()

    if (analytics) {
      logEvent(analytics, 'logout', { method: 'dashboard_avatar' })
    }

    window.location.href = '/auth/login/'
  }

  const goToSettingsPage = () => {
    navigate('/app/settings/')
    handleClose()
  }

  return (
    <React.Fragment>
      <IconButton
        id='settings-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        aria-label='Configuración'
        onClick={handleClick}
        sx={{ p: 1, m: -1 }}
      >
        <Settings />
      </IconButton>
      <Avatar>
        <Person />
      </Avatar>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'settings-button' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              boxShadow: 12,
              overflow: 'visible',
              mt: 0.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 16,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(-135deg)',
                zIndex: 0,
              },
            },
          },
        }}
      >
        {loggingOut ? (
          <MenuItem>
            Cerrando sesión...
          </MenuItem>
        ) : (
          [
            (
              <MenuItem
                key={'uid'}
                disabled
              >
                <ListItemIcon>
                  <Person fontSize='small' />
                </ListItemIcon>
                {getCredential()?.uid}
              </MenuItem>
            ),
            (
              <MenuItem
                key='settings'
                onClick={goToSettingsPage}
              >
                <ListItemIcon>
                  <Settings fontSize='small' />
                </ListItemIcon>
                Configurar cuenta
              </MenuItem>
            ),
            (
              <MenuItem
                key='logout'
                onClick={handleLogout}
              >
                <ListItemIcon>
                  <Logout fontSize='small' />
                </ListItemIcon>
                Cerrar sesión
              </MenuItem>
            ),
          ]
        )}
      </Menu>
    </React.Fragment>
  )
}
