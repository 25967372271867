import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from '../../components'
import {
  CREATE_BANK_WITHDRAWAL_MUTATION,
  RENEW_AUTHORIZATION_CODE_MUTATION,
  USER_OPERATIONS_QUERY,
} from '../../queries'
import { setFormError, translateError } from '../../services'

import type {
  BankAccount,
  CreateBankWithdrawalData,
  CreateBankWithdrawalVars,
  RenewAuthorizationCodeData,
  RenewAuthorizationCodeVars,
  SaleQuote,
} from '../../queries'
import type { FormikProps } from 'formik'

type FormValues = {
  authorizationCode: string
}

const initialValues: FormValues = {
  authorizationCode: '',
}

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    authorizationCode: Yup.string()
      .required('Este campo es obligatorio'),
  })

type InnerFormProps = FormikProps<FormValues> & {
  handleBack: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  handleBack,
}: InnerFormProps) => (
  <Form>
    <Typography>
      Ingresa el código que enviamos a tu email para autorizar esta operación.
    </Typography>
    <Field
      required
      inputProps={{
        autoComplete: 'off',
      }}
      name='authorizationCode'
      type='text'
      label={'Código de autorización'}
      component={TextField}
      margin='normal'
      fullWidth
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={handleBack}
          variant='outlined'
        >
          Regresar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          Confirmar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type AuthCodeRenewerProps = {
  handleBack: () => void
  setAuthCodeRenewed: (value: boolean) => void
}

const AuthCodeRenewer = ({
  handleBack,
  setAuthCodeRenewed,
}: AuthCodeRenewerProps) => {
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [updating, setUpdating] = React.useState(false)

  const [renewAuthorizationCode] =
    useMutation<RenewAuthorizationCodeData, RenewAuthorizationCodeVars>(RENEW_AUTHORIZATION_CODE_MUTATION, {
      errorPolicy: 'all',
    })

  const handleClick = async () => {
    setUpdating(true)
    const response = await renewAuthorizationCode()
    setUpdating(false)

    if (response.data?.renewAuthorizationCode) {
      setAuthCodeRenewed(true)
    } else {
      setErrorMsg(translateError(response))
    }
  }

  return (
    <React.Fragment>
      <Typography>
        Presiona el botón para que enviemos un código a tu email que te permitirá
        autorizar esta operación.
      </Typography>
      <ErrorDisplay
        errorMsg={errorMsg}
        mt={2}
      />
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={updating}
            onClick={handleBack}
            variant='outlined'
          >
            Regresar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={updating}
            onClick={handleClick}
            variant='contained'
          >
            Obtener código
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </React.Fragment>
  )
}

type AuthCodeFormProps = {
  cryptoAmount: number
  bankAccount: BankAccount | null
  saleQuote: SaleQuote
  handleBack: () => void
  handleNext: (saleQuote: SaleQuote) => void
}

export const AuthCodeForm = ({
  cryptoAmount,
  bankAccount,
  saleQuote,
  handleBack,
  handleNext,
}: AuthCodeFormProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [authCodeRenewed, setAuthCodeRenewed] = React.useState(false)

  const [createBankWithdrawal] =
    useMutation<CreateBankWithdrawalData, CreateBankWithdrawalVars>(
      CREATE_BANK_WITHDRAWAL_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          USER_OPERATIONS_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    if (!cryptoAmount || !bankAccount || !saleQuote) {
      setFormError(formRef, 'Los campos introducidos al formulario son inválidos')
      return
    }

    const response = await createBankWithdrawal({
      variables: {
        authorizationCode: values.authorizationCode,
        bankAccountId: bankAccount.id,
        providerId: saleQuote.providerId,
        quoteId: saleQuote.id,
      },
    })

    if (response.errors && response.errors.length > 0) {
      setFormError(formRef, translateError(response))
    } else {
      handleNext(saleQuote)
    }
  }

  return (
    <React.Fragment>
      <Typography
        variant='h6'
        component='p'
        textAlign='center'
      >
        Código de autorización
      </Typography>
      {(authCodeRenewed) ? (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <InnerForm
              handleBack={handleBack}
              {...props}
            />
          )}
        </Formik>
      ) : (
        <AuthCodeRenewer
          handleBack={handleBack}
          setAuthCodeRenewed={setAuthCodeRenewed}
        />
      )}
    </React.Fragment>
  )
}
