import * as React from 'react'

import { Wrapper } from './src/wrapper'

import type { GatsbyBrowser } from 'gatsby'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }, options) => (
  <Wrapper
    animatedLogo={options.animatedLogo as string | undefined}
    defaultOgImage={options.defaultOgImage as string | undefined}
  >
    {element}
  </Wrapper>
)
