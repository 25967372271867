import * as React from 'react'

import { Stack } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import {
  hasValidRut,
} from '../services'

import type { BusinessIdentity } from '../queries'

export type BusinessIdentityFormValues = {
  companyName: string
  identificationNumber: string
}

export const getBusinessIdentityInitialValues = (
  data?: BusinessIdentity,
): BusinessIdentityFormValues => ({
  companyName: data?.companyName || '',
  identificationNumber: data?.identificationNumber || '',
})

export const businessIdentityValidationSchema: Yup.SchemaOf<BusinessIdentityFormValues> =
  Yup.object({
    companyName: Yup.string()
      .required('Este campo es obligatorio'),
    identificationNumber: Yup.string()
      .required('Este campo es obligatorio')
      .test({
        message: 'El rut ingresado no es valido',
        test: (documentNumber) => hasValidRut(documentNumber),
      }),
  })

export const BusinessIdentityFields = () => (
  <Stack spacing={3}>
    <Field
      required
      name='companyName'
      type='text'
      label='Nombre Empresa'
      component={TextField}
    />
    <Field
      required
      name='identificationNumber'
      type='text'
      label='Rut empresa'
      component={TextField}
    />
  </Stack>
)
