import * as React from 'react'

import { Button } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Dialog, ErrorDisplay } from '../../components'
import {
  BaseIdentityFields,
  FundsManagerRelationshipFields,
  baseIdentityValidationSchema,
  fundsManagerRelationshipValidationSchema,
  getBaseIdentityInitialValues,
} from '../../forms'
import { toISO8601Date } from '../../services'

import type {
  BaseIdentityFormValues,
  FundsManagerRelationshipFormValues,
} from '../../forms'
import type { SpouseIdentity, SpouseIdentityInput } from '../../queries'
import type { FormikProps } from 'formik'

export type SpouseIdentityFormValues =
  BaseIdentityFormValues
  & FundsManagerRelationshipFormValues

export const getSpouseIdentityFormValues = (data?: SpouseIdentity): SpouseIdentityFormValues => ({
  ...getBaseIdentityInitialValues(data),
  fundsManagerRelationship: data?.fundsManagerRelationship.kind || '',
})

export const getSpouseIdentityMutationVariables =
  (data: SpouseIdentityFormValues): SpouseIdentityInput => ({
    ...data,
    birthdate: toISO8601Date(data.birthdate),
  })

export const spouseIdentityValidationSchema: Yup.SchemaOf<SpouseIdentityFormValues> =
  Yup.object().shape({
    ...baseIdentityValidationSchema.fields,
    ...fundsManagerRelationshipValidationSchema.fields,
  })

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<SpouseIdentityFormValues>) => (
  <Form style={{ width: '100%' }}>
    <BaseIdentityFields />
    <FundsManagerRelationshipFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      my={2}
    />
    <Button
      fullWidth
      disabled={isSubmitting || !isValid}
      onClick={submitForm}
      variant='contained'
    >
      Confirmar
    </Button>
  </Form>
)

export type SpouseIdentityFormProps = {
  setSpouseIdentity: (values: SpouseIdentityFormValues) => void
  spouseIdentity: SpouseIdentityFormValues
}

export const SpouseIdentityForm = ({
  setSpouseIdentity,
  spouseIdentity,
}: SpouseIdentityFormProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const handleSubmit = (values: SpouseIdentityFormValues) => {
    setSpouseIdentity(values)
    closeDialog()
  }

  return (
    <React.Fragment>
      <Button
        fullWidth
        onClick={openDialog}
        variant='contained'
        sx={{ mt: 1, mb: 2 }}
      >
        Agregar/editar datos del cónyugue
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title='Datos del cónyuge'
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <Formik
            initialValues={spouseIdentity}
            validationSchema={spouseIdentityValidationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => <InnerForm {...props} />}
          </Formik>
        </LocalizationProvider>
      </Dialog>
    </React.Fragment>
  )
}
