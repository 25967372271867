import * as React from 'react'

import { KeyboardDoubleArrowUp } from '@mui/icons-material'
import { Skeleton, Stack, Typography, alpha, styled } from '@mui/material'

import { AppContainer, Currency } from '../components'
import { DomainContext } from '../contexts'

import type { GridSize } from '@mui/material'

const CurrencySkeleton = styled(Skeleton)(({ theme }) => ({
  width: 100,
  '::after': {
    background: `linear-gradient(90deg, transparent, ${alpha(
      theme.palette.primary.light,
      theme.palette.action.disabledOpacity,
    )})`,
  },
}))

type BalanceProps = {
  loading: boolean
  currency: string
  assetAmount: number | undefined
  assetUsdPrice: number | undefined
  descriptionText: string
  icon?: React.ReactNode
  sm?: boolean | GridSize
  md?: boolean | GridSize
  px?: number
  py?: number
}

const Balance = ({
  loading,
  currency,
  assetAmount,
  assetUsdPrice,
  descriptionText,
  icon,
  sm = 12,
  md = 6,
  px = 3,
  py = 3,
}: BalanceProps) => {
  const { conversionCurrency } = React.useContext(DomainContext)

  const value = (() => {
    if (loading) {
      return undefined
    } else if (typeof assetAmount === 'undefined') {
      return 0
    }

    const price = currency === conversionCurrency ? assetUsdPrice : 1
    return (typeof price === 'undefined') ? undefined : price * assetAmount
  })()

  const isUndefined = typeof value === 'undefined'

  return (
    <AppContainer
      sm={sm}
      md={md}
      sx={{ px, py, backgroundColor: 'primary.dark', color: 'common.white' }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='h4'
          component='p'
          fontWeight={600}
          lineHeight='1.2'
          textAlign='center'
        >
          {isUndefined ? (
            <CurrencySkeleton animation='wave' />
          ) : (
            <Currency
              currency={currency}
              value={value}
            />
          )}
        </Typography>
        {!isUndefined && value > 0 && icon}
      </Stack>
      <Typography
        variant='h6'
        component='p'
        textAlign='center'
      >
        {descriptionText}
      </Typography>
    </AppContainer>
  )
}

export const MainBalance = (props: Omit<BalanceProps, 'descriptionText' | 'icon'>) => (
  <Balance
    descriptionText='Balance total'
    {...props}
  />
)

export const EarningsBalance = (props: Omit<BalanceProps, 'descriptionText' | 'icon'>) => (
  <Balance
    descriptionText='Recompensas'
    icon={<KeyboardDoubleArrowUp />}
    {...props}
  />
)

type VariationBalanceProps =
  Omit<BalanceProps, 'assetAmount' | 'assetUsdPrice' | 'descriptionText' | 'icon'> & {
    range: string
    variation: number
  }

export const VariationBalance = ({
  range,
  variation,
  ...rest
}: VariationBalanceProps) => (
  <Balance
    assetAmount={variation}
    assetUsdPrice={1}
    descriptionText={`Variación ${getRangeText(range)}`}
    {...rest}
  />
)

const getRangeText = (range?: string) => {
  switch (range) {
  case '24h': return 'en 24 horas'
  case '7d': return 'en 7 días'
  case '30d': return 'en 30 días'
  case '1y': return 'en 1 año'
  case 'all': return 'desde el principio'
  }
}
