import { gql } from '@apollo/client'

export type BusinessIdentity = {
  id: string
  companyName: string
  identificationNumber: string
}

export const BUSINESS_IDENTITY_FIELDS = gql`
  fragment BusinessIdentityFields on BusinessIdentity {
    id
    companyName
    identificationNumber
  }
`

export type BusinessIdentityVars = Record<string, never>

export type BusinessIdentityData = {
  businessIdentity: BusinessIdentity
}

export const BUSINESS_IDENTITY_QUERY = gql`
  ${BUSINESS_IDENTITY_FIELDS}
  query BusinessIdentity {
    businessIdentity {
      ...BusinessIdentityFields
    }
  }
`

export type UpdateBusinessIdentityVars = {
  companyName: string
  identificationNumber: string
  primaryPhoneNumber: string
  secondaryPhoneNumber?: string
}

export type UpdateBusinessIdentityData = {
  updateBusinessIdentity: string
  updatePhoneNumber: string
}

export const UPDATE_BUSINESS_IDENTITY_MUTATION = gql`
  mutation UpdateBusinessIdentity(
    $companyName: String!
    $identificationNumber: String!
    $primaryPhoneNumber: String!
    $secondaryPhoneNumber: String
  ) {
    updateBusinessIdentity(input: {
      companyName: $companyName
      identificationNumber: $identificationNumber
    })
    updatePhoneNumber(input: {
      primary: $primaryPhoneNumber
      secondary: $secondaryPhoneNumber
    })
  }
`
