import { gql } from '@apollo/client'

import { FUNDS_MANAGER_RELATIONSHIP_FIELDS } from './funds_manager_relationships'

import type { FundsManagerRelationship } from './funds_manager_relationships'
import type { CivilState } from './legal_identities'

export type RepresentativeIdentity = {
  id: string
  givenName: string
  familyName: string
  birthdate: string
  identificationNumber: string
  nationality: string
  civilState: CivilState
  fundsManagerRelationship: FundsManagerRelationship
}

export const REPRESENTATIVE_IDENTITY_FIELDS = gql`
  ${FUNDS_MANAGER_RELATIONSHIP_FIELDS}
  fragment RepresentativeIdentityFields on RepresentativeIdentity {
    id
    givenName
    familyName
    birthdate
    identificationNumber
    nationality
    civilState
    fundsManagerRelationship {
      ...FundsManagerRelationshipFields
    }
  }
`

export type RepresentativeIdentityVars = Record<string, never>

export type RepresentativeIdentityData = {
  representativeIdentity: RepresentativeIdentity
}

export const REPRESENTATIVE_IDENTITY_QUERY = gql`
  ${REPRESENTATIVE_IDENTITY_FIELDS}
  query RepresentativeIdentity {
    representativeIdentity {
      ...RepresentativeIdentityFields
    }
  }
`

export type DeleteRepresentativeIdentityVars = Record<string, never>

export type DeleteRepresentativeIdentityData = {
  deleteRepresentativeIdentity: string
}

export const DELETE_REPRESENTATIVE_IDENTITY_MUTATION = gql`
  mutation DeleteRepresentativeIdentity {
    deleteRepresentativeIdentity(input: {})
  }
`

export type UpdateRepresentativeIdentityVars = {
  givenName: string
  familyName: string
  birthdate: string
  identificationNumber: string
  nationality: string
  civilState: string
  fundsManagerRelationship: string
}

export type UpdateRepresentativeIdentityData = {
  updateRepresentativeIdentity: string
}

export const UPDATE_REPRESENTATIVE_IDENTITY_MUTATION = gql`
  mutation UpdateRepresentativeIdentity(
    $givenName: String!
    $familyName: String!
    $birthdate: ISO8601Date!
    $identificationNumber: String!
    $nationality: String!
    $civilState: String!
    $fundsManagerRelationship: String!
  ) {
    updateRepresentativeIdentity(
      input: {
        givenName: $givenName
        familyName: $familyName
        birthdate: $birthdate
        identificationNumber: $identificationNumber
        nationality: $nationality
        civilState: $civilState
        fundsManagerRelationship: $fundsManagerRelationship
      }
    )
  }
`
